import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import ReactMarkdown from "react-markdown"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import Services from "../components/services"

import { Container, Row, Col } from "react-bootstrap"

// Styles
import serviceStyles from "../pages/angebot.module.scss"

export const query = graphql`
    query {
        heroBackground: file(relativePath: { regex: "/rainbow/" }) {
            childImageSharp {
                fluid
                {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        logo: file(relativePath: { regex: "/logo_transparent/" }) {
            childImageSharp {
                fluid
                {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        strapiOffer {
            heroSubtitle
            heroTitle
            intro
            introTitle
        }

    }
`

const AngebotPage = ({ data }) => {

    const offer = data.strapiOffer

    return (
        <Layout>
            <SEO title="Angebot" />
            <BackgroundImage
                className={`backgroundImage`}
                fluid={data.heroBackground.childImageSharp.fluid}
            >
                <Container className={`hero`} />
            </BackgroundImage>

            <Container className={`heroTextBox mainMid`}>
                <Row className={`justify-content-center`}>
                    <Col>
                        <h1 className={`smallH1`}>{offer.heroTitle}</h1>
                        <span className={`headline`}>{offer.heroSubtitle}</span>
                    </Col>
                </Row>
            </Container>

            {/* Section 2 */}
            <Container className={`mainMid my-5`}>
                <Row>
                    <Col className={`text-center`}>
                        <h2>
                            {offer.introTitle}
                        </h2>
                    </Col>
                </Row>
                <Row>
                    <Col className={`text-center`}>

                        {/* <ReactMarkdown source={offer.intro === "" ? "" : offer.intro} escapeHtml={false} /> */}
                        <ReactMarkdown source={offer.intro} escapeHtml={false} />
                    </Col>
                </Row>

                <Services useOpacity={true} />

            </Container>
        </Layout>
    )
}

export default AngebotPage
